/** Light color-scheme **/
:root {
    --cc-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --cc-font-size: 16px;
    --cc-bg: #fff;
    // --cc-bg: var(--cc-pagePrimary-backgroundColor);
    --cc-z-index: 2147483647;
    --cc-text: #07132d;
    --cc-border-radius: 0.45rem;
    --cc-btn-primary-bg: #0d1d41;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #3a4765;
    --cc-btn-primary-hover-text: var(--cc-btn-primary-text);
    --cc-btn-secondary-bg: #eaeff2;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #d8e0e6;
    --cc-btn-secondary-hover-text: var(--cc-btn-secondary-text);
    --cc-btn-border-radius: 0.375rem;
    --cc-toggle-bg-off: #919ea6;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #d5dee2;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    // --cc-cookie-category-block-bg: #f0f4f7;
    // --cc-cookie-category-block-bg-hover: #e9eff4;
    --cc-cookie-category-block-bg: var(--cc-pageSecondary-backgroundColor);
    --cc-cookie-category-block-bg-hover: var(--cc-pageSecondary-backgroundColor);
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e9edf2;
    --cc-overlay-bg: rgb(4, 6, 8);
    --cc-overlay-opacity: 0.85;
    --cc-consent-modal-box-shadow: 0 0.625rem 1.875rem rgba(2, 2, 3, 0.28);
    --cc-webkit-scrollbar-bg: #cfd5db;
    --cc-webkit-scrollbar-bg-hover: #9199a0;
  }
  
  /** Dark color-scheme **/
  .c_darkmode {
    --cc-bg: #181b1d;
    // --cc-bg: var(--cc-pagePrimary-backgroundColor);
    --cc-text: #d8e5ea;
    --cc-btn-primary-bg: #a6c4dd;
    --cc-btn-primary-text: #000;
    --cc-btn-primary-hover-bg: #c2dff7;
    --cc-btn-primary-hover-text: var(--cc-btn-primary-text);
    --cc-btn-secondary-bg: #33383c;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #3e454a;
    --cc-btn-secondary-hover-text: var(--cc-btn-secondary-text);
    --cc-toggle-bg-off: #667481;
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: #454c54;
    --cc-block-text: #b3bfc5;
    // --cc-cookie-category-block-bg: #23272a;
    // --cc-cookie-category-block-bg-hover: #2b3035;
    --cc-cookie-category-block-bg: var(--cc-pageSecondary-backgroundColor);
    --cc-cookie-category-block-bg-hover: var(--cc-pageSecondary-backgroundColor);
    --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
    --cc-toggle-knob-icon-color: var(--cc-bg);
    --cc-section-border: #292d31;
    --cc-cookie-table-border: #2b3035;
    --cc-webkit-scrollbar-bg: #667481;
    --cc-webkit-scrollbar-bg-hover: #9199a0;
  }
  
  #cc--main {
    z-index: var(--cc-z-index);
  }
  
  .cc_div *,
  .cc_div *:hover,
  .cc_div :before,
  .cc_div :after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: none;
    font-style: inherit;
    font-variant: normal;
    font-weight: inherit;
    font-family: inherit;
    line-height: 1.2;
    font-size: 1em;
    transition: none;
    animation: none;
    margin: 0;
    padding: 0;
    text-transform: none;
    letter-spacing: unset;
    // color: var(--cc-pagePrimary-textColor);
    background: none;
    border: none;
    border-radius: unset;
    box-shadow: none;
    text-decoration: none;
    text-align: left;
    visibility: unset;
    height: auto;
    vertical-align: baseline;
  }
  
  #c-s-bn{
    color: var(--cc-ctaSecondary-textColor)
  }
  .cc_div {
    font-size: var(--cc-font-size);
    font-weight: 400;
    font-family: var(--cc-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: var(--cc-text);
  }
  
  .cc_div .c-bn,
  .cc_div .b-tl,
  #s-ttl,
  #c-ttl,
  #s-bl td:before {
    font-weight: 600;
  }
  
  #cm,
  #s-inr,
  .cc_div .c-bl,
  .cc_div .b-tl,
  #s-bl .act .b-acc {
    border-radius: var(--cc-border-radius);
    // color: var(--cc-pageSecondary-textColor)
  }
  
  #s-bl .act .b-acc {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: var(--cc-pageSecondary-textColor)
  }
  
  .cc_div input,
  .cc_div button,
  .cc_div a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: hidden;
  }
  
  .cc_div a {
    border-bottom: 1px solid;
  }
  
  .cc_div a:hover {
    text-decoration: none;
    border-color: transparent;
  }
  
  .cc_div .c-bn > span {
    pointer-events: none;
  }
  
  /* Make elements "animatable" */
  .c--anim #cm,
  .c--anim #s-cnt,
  .c--anim #s-inr,
  #cs-ov,
  #cm-ov {
    transition: visibility 0.25s linear, opacity 0.25s ease, transform 0.25s ease !important;
  }
  
  .c--anim .c-bn {
    transition: background-color 0.25s ease !important;
  }
  
  /* start transitions */
  .c--anim #cm.bar.slide,
  .c--anim .bar.slide #s-inr {
    transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
  }
  
  .c--anim #cm.bar.slide + #cm-ov,
  .c--anim .bar.slide + #cs-ov {
    transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
  }
  
  #cm.bar.slide,
  .cc_div .bar.slide #s-inr {
    transform: translateX(100%);
    opacity: 1;
  }
  
  #cm.bar.top.slide,
  .cc_div .bar.left.slide #s-inr {
    transform: translateX(-100%);
    opacity: 1;
  }
  
  #cm.slide,
  .cc_div .slide #s-inr {
    transform: translateY(1.6em);
  }
  
  #cm.top.slide {
    transform: translateY(-1.6em);
  }
  
  #cm.bar.slide {
    transform: translateY(100%);
  }
  
  #cm.bar.top.slide {
    transform: translateY(-100%);
  }
  /* end transitions */
  
  /* Show modals */
  
  .show--consent .c--anim #cm,
  .show--consent .c--anim #cm.bar,
  .show--settings .c--anim #s-inr,
  .show--settings .c--anim .bar.slide #s-inr {
    opacity: 1;
    transform: scale(1);
    visibility: visible !important;
  }
  
  .show--consent .c--anim #cm.box.middle,
  .show--consent .c--anim #cm.cloud.middle {
    transform: scale(1) translateY(-50%);
  }
  
  .show--settings .c--anim #s-cnt {
    visibility: visible !important;
  }
  
  /* Show overlays */
  .force--consent.show--consent .c--anim #cm-ov,
  .show--settings .c--anim #cs-ov {
    visibility: visible !important;
    opacity: var(--cc-overlay-opacity) !important;
  }
  
  #cm {
    font-family: inherit;
    padding: 1em 1.4em 1.3em 1.4em;
    position: fixed;
    z-index: 1;
    background: var(--cc-pagePrimary-backgroundColor);
    max-width: 24.2em;
    width: 100%;
    bottom: 1.25em;
    right: 1.25em;
    box-shadow: 0 0.625em 1.875em #000000;
    box-shadow: var(--cc-consent-modal-box-shadow);
    opacity: 0;
    visibility: hidden;
    transform: scale(0.95);
    line-height: initial;
  }
  
  /** fix https://github.com/orestbida/cookieconsent/issues/94 **/
  #cc_div #cm {
    display: block !important;
  }
  
  #c-ttl {
    margin-bottom: 0.7em;
    font-size: 1.05em;
  }
  
  .cloud #c-ttl {
    margin-top: -0.15em;
  }
  
  #c-txt {
    font-size: 0.9em;
    line-height: 1.5em;
    color: var(--cc-pagePrimary-textColor)
  }
  
  .cc_div #c-bns {
    display: flex;
    justify-content: space-between;
    margin-top: 1.4em;
  }
  
  .cc_div .c-bn {
    color: var(--cc-ctaSecondary-textColor);
    background: var(--cc-ctaSecondary-backgroundColor);
    padding: 1em 1.7em;
    display: inline-block;
    cursor: pointer;
    font-size: 0.82em;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    text-align: center;
    border-radius: var(--cc-btn-border-radius);
    flex: 1;
  }
  
  #c-bns button + button,
  #s-cnt button + button,
  #s-c-bn {
    float: right;
    margin-left: 0.5rem;
  }
  
  #s-cnt #s-rall-bn {
    float: none;
  }
  
  #cm .c_link:hover,
  #cm .c_link:active,
  #s-cnt button + button:hover,
  #s-cnt button + button:active,
  #s-c-bn:active,
  #s-c-bn:hover {
    // background: var(--cc-btn-secondary-hover-bg);
    // color: var(--cc-btn-secondary-hover-text);
  }
  
  /**
  CookieConsent settings modal
  **/
  #s-cnt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    display: table;
    height: 100%;
    visibility: hidden;
  }
  
  // #cc-main {
  //   left: var(--cc-modal-margin);
  //   margin: 0 auto;
  //   right: var(--cc-modal-margin);
  //   width: unset;
  // }
  // #cc-main {
  //   top: 50%;
  //   transform: translateY(calc(-50% + 1.6em));
  // }

  // .show--consent #cc-main .cc--anim .cm--middle {
  //   transform: translateY(-50%);
  // }

  #s-bl {
    outline: none;
    scrollbar-width: thin;
  }
  
  #s-bl .title {
    margin-top: 1.4em;
  }
  
  #s-bl .title:first-child {
    margin-top: 0;
  }
  
  #s-bl .b-bn {
    margin-top: 0;
  }
  
  #s-bl .b-acc .p {
    margin-top: 0;
    padding: 1em;
    color: var(--cc-pageSecondary-textColor)
  }
  
  #s-cnt .b-bn .b-tl {
    display: block;
    font-family: inherit;
    font-size: 0.95em;
    width: 100%;
    position: relative;
    padding: 1.3em 6.4em 1.3em 2.7em;
    background: none;
    transition: background-color 0.25s ease;
  }
  
  #s-cnt .b-bn .b-tl.exp {
    cursor: pointer;
  }
  
  #s-cnt .act .b-bn .b-tl {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  #s-cnt .b-bn .b-tl:active,
  #s-cnt .b-bn .b-tl:hover {
    background: var(--cc-cookie-category-block-bg-hover);
  }
  
  #s-bl .b-bn {
    position: relative;
  }
  
  #s-bl .c-bl {
    padding: 1em;
    margin-bottom: 0.4rem;
    border: 1px solid var(--cc-section-border);
    transition: background-color 0.25s ease;
  }
  
  #s-bl .c-bl:hover {
    // background: var(--cc-cookie-category-block-bg);
  }
  
  #s-bl .c-bl:last-child {
    margin-bottom: 0.5em;
  }
  
  #s-bl .c-bl:first-child {
    transition: none;
    padding: 0;
    margin-top: 0;
    border: none;
    margin-bottom: 2em;
  }
  
  #s-bl .c-bl:not(.b-ex):first-child:hover {
    background: transparent;
    background: unset;
  }
  
  #s-bl .c-bl.b-ex {
    padding: 0;
    border: none;
    background: var(--cc-cookie-category-block-bg);
    transition: none;
  }
  
  #s-bl .c-bl.b-ex + .c-bl {
    margin-top: 2em;
  }
  
  #s-bl .c-bl.b-ex + .c-bl.b-ex {
    margin-top: 0;
  }
  
  #s-bl .c-bl.b-ex:first-child {
    margin-bottom: 1em;
  }
  
  #s-bl .c-bl.b-ex:first-child {
    margin-bottom: 0.5em;
  }
  
  #s-bl .b-acc {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    margin-bottom: 0;
    display: none;
    color: var(--cc-pageSecondary-textColor)
  }
  
  #s-bl .act .b-acc {
    max-height: 100%;
    display: block;
    overflow: hidden;
    color: var(--cc-pageSecondary-textColor)
  }
  
  #s-cnt .p {
    font-size: 0.9em;
    line-height: 1.5em;
    margin-top: 0.85em;
    // color: var(--cc-block-text);
    color: var(--cc-pagePrimary-textColor)
  }
  
  .cc_div .b-tg .c-tgl:disabled {
    cursor: not-allowed;
  }
  
  #c-vln {
    display: table-cell;
    vertical-align: middle;
    position: relative;
  }
  
  #cs {
    padding: 0 1.7em;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
  }
  
  #s-inr {
    height: 100%;
    position: relative;
    max-width: 45em;
    margin: 0 auto;
    transform: scale(0.96);
    opacity: 0;
    padding-top: 4.75em;
    padding-bottom: 4.75em;
    position: relative;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    box-shadow: rgba(3, 6, 9, 0.26) 0px 13px 27px -5px;
  }
  
  #s-inr,
  #s-hdr,
  #s-bns {
    background: var(--cc-pagePrimary-backgroundColor);
  }
  
  #s-bl {
    overflow-y: auto;
    overflow-y: overlay;
    overflow-x: hidden;
    height: 100%;
    padding: 1.3em 1.6em;
    display: block;
    width: 100%;
  }
  
  #s-bns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em 1.8em;
    border-top: 1px solid var(--cc-section-border);
    height: 4.75em;
  }
  
  .cc_div .cc-link {
    color: var(--cc-ctaPrimary-textColor);
    border-bottom: 1px solid var(--cc-ctaPrimary-textColor);
    display: inline;
    padding-bottom: 0;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
  }
  
  .cc_div .cc-link:hover,
  .cc_div .cc-link:active {
    border-color: transparent;
  }
  
  #c-bns button:first-child,
  #s-bns button:first-child {
    color: var(--cc-ctaPrimary-textColor);
    // background: var(--cc-btn-primary-bg);
    background-color: var(--cc-ctaPrimary-backgroundColor);
  }
  
  #c-bns.swap button:first-child {
    color: var(--cc-ctaSecondary-textColor);
    background: var(--cc-ctaSecondary-backgroundColor);
  }
  
  #c-bns.swap button:last-child {
    color: var(--cc-ctaPrimary-textColor);
    background: var(--cc-ctaPrimary-textColor);
  }
  div.b-tl {
    color: var(--cc-ctaPrimary-textColor);
  }
  button.b-tl.exp {
    color: var(--cc-ctaSecondary-textColor);
  }
  
  .cc_div .b-tg .c-tgl:checked ~ .c-tg {
    // background: var(--cc-toggle-bg-on);
    background: var(--cc-ctaPrimary-backgroundColor);
  }
  
  #c-bns button:first-child:active,
  #c-bns button:first-child:hover,
  #s-bns button:first-child:active,
  #s-bns button:first-child:hover {
    // background: var(--cc-btn-primary-hover-bg);
    background-color: var(--cc-ctaPrimary-backgroundColor);
    color: var(--cc-ctaPrimary-textColor);
  }

  #c-bns.swap button:last-child:active,
  #c-bns.swap button:last-child:hover {
    // background: var(--cc-btn-primary-hover-bg);
    background-color: var(--cc-ctaSecondary-backgroundColor);
    color: var(--cc-ctaSecondary-textColor);
  }
  
  #c-bns.swap button:first-child:active,
  #c-bns.swap button:first-child:hover {
    background: var(--cc-btn-secondary-hover-bg);
    color: var(--cc-btn-secondary-hover-text);
  }
  
  #s-hdr {
    position: absolute;
    top: 0;
    width: 100%;
    display: table;
    padding: 0 1.8em;
    height: 4.75em;
    vertical-align: middle;
    z-index: 2;
    border-bottom: 1px solid var(--cc-section-border);
    h2{
		font-size: 18px;
		font-weight: bold;
		position: relative;
	}
  }
  
  #s-ttl {
    display: table-cell;
    vertical-align: middle;
    font-size: 1em;
    color: var(--cc-pagePrimary-textColor)
  }
  
  #s-c-bn {
    padding: 0;
    width: 1.7em;
    height: 1.7em;
    font-size: 1.45em;
    margin: 0;
    font-weight: initial;
    position: relative;
    overflow: hidden;
  }
  
  #s-c-bnc {
    display: table-cell;
    vertical-align: middle;
  }
  
  .cc_div span.t-lb {
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
  }
  
  #c_policy__text {
    height: 31.25em;
    overflow-y: auto;
    margin-top: 1.25em;
  }
  
  #c-s-in {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    height: 100%;
    height: calc(100% - 2.5em);
    max-height: 37.5em;
  }
  #s-all-bn {
    display: none;
  }
  #s-rall-bn {
    display: none;
  }
  #s-sv-bn, #s-sv-bn:hover {
    // display: none;
    color: #ffffff;
    background-color: var(--cc-ctaPrimary-backgroundColor);
  }
  
  @media screen and (min-width: 688px) {
    /** works only on webkit-based browsers **/
    #s-bl::-webkit-scrollbar {
      width: 0.8em;
      height: 100%;
      background: transparent;
      border-radius: 0 0.25em 0.25em 0;
    }
  
    #s-bl::-webkit-scrollbar-thumb {
      border: 0.25em solid var(--cc-pagePrimary-backgroundColor);
      background: var(--cc-webkit-scrollbar-bg);
      border-radius: 100em;
    }
  
    #s-bl::-webkit-scrollbar-thumb:hover {
      background: var(--cc-webkit-scrollbar-bg-hover);
    }
  
    #s-bl::-webkit-scrollbar-button {
      width: 10px;
      height: 5px;
    }
  }
  
  /** custom checkbox **/
  /* The container */
  .cc_div .b-tg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: inline-block;
    margin: auto;
    right: 1.2em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
  }
  
  /* Hide the browser's default checkbox */
  .cc_div .b-tg .c-tgl {
    position: absolute;
    cursor: pointer;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    border: 0;
  }
  
  /* Create a custom checkbox */
  .cc_div .b-tg .c-tg {
    position: absolute;
    background: var(--cc-toggle-bg-off);
    transition: background-color 0.25s ease, box-shadow 0.25s ease;
    pointer-events: none;
  }
  
  .cc_div span.t-lb,
  .cc_div .b-tg,
  .cc_div .b-tg .c-tg,
  .cc_div .b-tg .c-tgl {
    width: 3.4em;
    height: 1.5em;
    border-radius: 4em;
  }
  
  .cc_div .b-tg .c-tg.c-ro {
    cursor: not-allowed;
  }
  
  .cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
    background: var(--cc-toggle-bg-readonly);
  }
  
  .cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
    box-shadow: none;
  }
  
  /* Style the checkmark/indicator */
  .cc_div .b-tg .c-tg:after {
    content: "";
    position: relative;
    display: block;
    left: 0.125em;
    top: 0.125em;
    width: 1.25em;
    height: 1.25em;
    border: none;
    box-sizing: content-box;
    background: var(--cc-toggle-knob-bg);
    box-shadow: 0 1px 2px rgba(24, 32, 35, 0.36);
    transition: transform 0.25s ease;
    border-radius: 100%;
  }
  
  /* Show the checkmark when checked */
  .cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
    transform: translateX(1.9em);
  }
  
  #s-bl table,
  #s-bl th,
  #s-bl td {
    border: none;
  }
  
  #s-bl tbody tr {
    transition: background-color 0.25s ease;
  }
  
  #s-bl tbody tr:hover {
    background: var(--cc-cookie-category-block-bg-hover);
  }
  
  #s-bl table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  
  #s-bl caption {
    padding: 0.5rem 1rem;
    font-size: 0.9em;
    border-bottom: 1px solid var(--cc-cookie-table-border);
    font-weight: 600;
  }
  
  #s-bl td,
  #s-bl th {
    padding: 0.8em 0.625em;
    text-align: left;
    vertical-align: top;
    font-size: 0.8em;
    padding-left: 1.2em;
  }
  
  #s-bl th {
    font-family: inherit;
    padding: 1em 1rem;
    font-weight: 600;
  }
  
  #s-bl thead tr:first-child {
    border-bottom: 1px solid var(--cc-cookie-table-border);
  }
  
  .force--consent #s-cnt,
  .force--consent #cs {
    width: 100vw;
  }
  
  #cm-ov,
  #cs-ov {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    background: #070707;
    background: var(--cc-overlay-bg);
    display: none;
    transition: none;
  }
  
  .show--settings #cs-ov,
  .c--anim #cs-ov,
  .force--consent .c--anim #cm-ov,
  .force--consent.show--consent #cm-ov {
    display: block;
  }
  
  #cs-ov {
    z-index: 2;
  }
  
  .force--consent .cc_div {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    width: 100vw;
    visibility: hidden;
    transition: visibility 0.25s linear;
  }
  
  .force--consent.show--consent .c--anim .cc_div,
  .force--consent.show--settings .c--anim .cc_div {
    visibility: visible;
  }
  
  .force--consent #cm {
    position: absolute;
  }
  
  .force--consent #cm.bar {
    width: 100vw;
    max-width: 100vw;
  }
  
  html.force--consent.show--consent {
    overflow-y: hidden !important;
  }
  
  html.force--consent.show--consent,
  html.force--consent.show--consent body {
    height: auto !important;
    overflow-x: hidden !important;
  }
  /** END BLOCK PAGE SCROLL */
  
  /** BEGIN ICONS **/
  .cc_div .b-bn .exp::before,
  .cc_div .act .b-bn .exp::before {
    border: solid var(--cc-ctaSecondary-textColor);
    border-width: 0 2px 2px 0;
    padding: 0.2em;
    display: inline-block;
    position: absolute;
    content: "";
    margin-right: 15px;
    position: absolute;
    transform: translateY(-50%) rotate(45deg);
    left: 1.2em;
    top: 50%;
  }
  
  .cc_div .act .b-bn .b-tl::before {
    transform: translateY(-20%) rotate(225deg);
  }
  
  .cc_div .on-i::before {
    border: solid var(--cc-toggle-knob-icon-color);
    border-width: 0 2px 2px 0;
    padding: 0.1em;
    display: inline-block;
    padding-bottom: 0.45em;
    content: "";
    margin: 0 auto;
    transform: rotate(45deg);
    top: 0.37em;
    left: 0.75em;
    position: absolute;
  }
  
  #s-c-bn::before,
  #s-c-bn::after {
    content: "";
    position: absolute;
    left: 0.82em;
    top: 0.58em;
    height: 0.6em;
    width: 1.5px;
    background: var(--cc-ctaSecondary-textColor);
    transform: rotate(45deg);
    border-radius: 1em;
    margin: 0 auto;
  }
  
  #s-c-bn::after {
    transform: rotate(-45deg);
  }
  
  .cc_div .off-i,
  .cc_div .on-i {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    display: block;
    text-align: center;
    transition: opacity 0.15s ease;
  }
  
  .cc_div .on-i {
    left: 0;
    opacity: 0;
  }
  
  .cc_div .off-i::before,
  .cc_div .off-i::after {
    right: 0.8em;
    top: 0.42em;
    content: " ";
    height: 0.7em;
    width: 0.09375em;
    display: block;
    background: var(--cc-toggle-knob-icon-color);
    margin: 0 auto;
    position: absolute;
    transform-origin: center;
  }
  
  .cc_div .off-i::before {
    transform: rotate(45deg);
  }
  .cc_div .off-i::after {
    transform: rotate(-45deg);
  }
  
  .cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
    opacity: 1;
  }
  .cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
    opacity: 0;
  }
  /** END ICONS **/
  
  #cm.box.middle,
  #cm.cloud.middle {
    top: 50%;
    transform: translateY(-37%);
    bottom: auto;
  }
  
  #cm.box.middle.zoom,
  #cm.cloud.middle.zoom {
    transform: scale(0.95) translateY(-50%);
  }
  
  #cm.box.center {
    left: 1em;
    right: 1em;
    margin: 0 auto;
  }
  
  /* Start cloud layout */
  #cm.cloud {
    max-width: 50em;
    margin: 0 auto;
    text-align: center;
    left: 1em;
    right: 1em;
    overflow: hidden;
    padding: 1.2em 1.7em;
    width: unset;
  }
  
  .cc_div .cloud #c-inr {
    display: table;
    width: 100%;
  }
  
  .cc_div .cloud #c-inr-i {
    width: 70%;
    display: table-cell;
    vertical-align: top;
    padding-right: 2.4em;
    h2{
      font-size: 18px;
      font-weight: bold;
      position: relative;
      color: var(--cc-pagePrimary-textColor);
      width: 100%;
    }
  }
  
  .cc_div .cloud #c-txt {
    font-size: 0.85em;
    color: var(--cc-pagePrimary-textColor)
  }
  
  .cc_div .cloud #c-bns {
    min-width: 170px;
    display: table-cell;
    vertical-align: middle;
  }
  
  #cm.cloud .c-bn {
    margin: 0.5rem 0 0 0;
    width: 100%;
  }
  
  #cm.cloud .c-bn:first-child {
    margin: 0;
  }
  
  #cm.cloud.left {
    margin-right: 1.25em;
  }
  
  #cm.cloud.right {
    margin-left: 1.25em;
  }
  /* End cloud layout */
  
  /* Start bar layout */
  #cm.bar {
    width: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    position: fixed;
    padding: 2em;
  }
  
  #cm.bar #c-inr {
    max-width: 32em;
    margin: 0 auto;
  }
  
  #cm.bar #c-bns {
    max-width: 33.75em;
  }
  
  #cm.bar #cs {
    padding: 0;
  }
  
  .cc_div .bar #c-s-in {
    top: 0;
    transform: none;
    height: 100%;
    max-height: 100%;
  }
  
  .cc_div .bar #s-hdr,
  .cc_div .bar #s-bl,
  .cc_div .bar #s-bns {
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
  
  .cc_div .bar #cs {
    padding: 0;
  }
  
  /* align bar to right by default */
  .cc_div .bar #s-inr {
    margin: 0;
    margin-left: auto;
    margin-right: 0;
    border-radius: 0;
    max-width: 32em;
  }
  
  .cc_div .bar.left #s-inr {
    margin-left: 0;
    margin-right: auto;
  }
  
  /* Force table to not be like tables anymore */
  .cc_div .bar #s-bl table,
  .cc_div .bar #s-bl thead,
  .cc_div .bar #s-bl tbody,
  .cc_div .bar #s-bl th,
  .cc_div .bar #s-bl td,
  .cc_div .bar #s-bl tr,
  .cc_div .bar #s-cnt {
    display: block;
  }
  
  .cc_div .bar #s-bl caption {
    display: block;
    border-top: 0;
    border-bottom: 1px solid var(--cc-cookie-table-border);
  }
  
  /* Hide table headers (but not display: none;, for accessibility) */
  .cc_div .bar #s-bl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  
  .cc_div .bar #s-bl tr {
    border-top: 1px solid var(--cc-cookie-table-border);
  }
  
  .cc_div .bar #s-bl td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 35%;
  }
  
  .cc_div .bar #s-bl td:before {
    position: absolute;
    left: 1rem;
    padding-right: 0.625em;
    white-space: nowrap;
    content: attr(data-column);
    color: var(--cc-text);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* End bar layout */
  
  /* Positions */
  #cm.top {
    bottom: auto;
    top: 1.25em;
  }
  
  #cm.left {
    right: auto;
    left: 1.25em;
  }
  
  #cm.right {
    left: auto;
    right: 1.25em;
  }
  
  #cm.bar.left,
  #cm.bar.right {
    left: 0;
    right: 0;
  }
  
  #cm.bar.top {
    top: 0;
  }
  /* end positions */
  
  /* Disable transitions if prefers-reduced-motion detected */
  @media (prefers-reduced-motion) {
    #cc--main #cm,
    #cc--main #s-cnt,
    #cc--main #s-inr {
      transition: none !important;
    }
  }
  
  @media screen and (max-width: 688px) {
    #cm,
    #cm.cloud,
    #cm.left,
    #cm.right {
      width: auto;
      max-width: 100%;
      margin: 0;
      padding: 1.2em !important;
      right: 1em;
      left: 1em;
      bottom: 1em;
      display: block;
    }
  
    .force--consent #cm,
    .force--consent #cm.cloud {
      width: auto;
      max-width: 100vw;
    }
  
    #cm.top {
      top: 1em;
      bottom: auto;
    }
  
    #cm.bottom {
      bottom: 1em;
      top: auto;
    }
  
    #cm.bar.bottom {
      bottom: 0;
    }
  
    #cm.cloud .c-bn {
      font-size: 0.85em;
    }
  
    #s-bns,
    .cc_div .bar #s-bns {
      padding: 1em 1.3em;
    }
  
    .cc_div .bar #s-inr {
      max-width: 100%;
      width: 100%;
    }
  
    .cc_div .cloud #c-inr-i {
      padding-right: 0;
    }
  
    #cs {
      border-radius: 0;
      padding: 0;
    }
  
    #c-s-in {
      max-height: 100%;
      height: 100%;
      top: 0;
      transform: none;
    }
  
    .cc_div .b-tg {
      transform: scale(1.1);
      right: 1.1em;
    }
  
    #s-inr {
      margin: 0;
      padding-bottom: 7.9em;
      border-radius: 0;
    }
  
    #s-bns {
      height: 7.9em;
    }
  
    #s-bl,
    .cc_div .bar #s-bl {
      padding: 1.2em;
    }
  
    #s-hdr,
    .cc_div .bar #s-hdr {
      padding: 0 1.2em;
    }
  
    /** dynamic table layout **/
    #s-bl table {
      width: 100%;
    }
  
    #s-inr.bns-t {
      padding-bottom: 10.5em;
    }
  
    .bns-t #s-bns {
      height: 10.5em;
    }
  
    .cc_div .bns-t .c-bn {
      font-size: 0.83em;
      padding: 0.9em 1.6em;
    }
  
    #s-cnt .b-bn .b-tl {
      padding-top: 1.2em;
      padding-bottom: 1.2em;
    }
  
    /* Force table to not be like tables anymore */
    #s-bl table,
    #s-bl thead,
    #s-bl tbody,
    #s-bl th,
    #s-bl td,
    #s-bl tr,
    #s-cnt {
      display: block;
    }
  
    #s-bl caption {
      border-bottom: 0;
      display: block;
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    #s-bl thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    #s-bl tr {
      border-top: 1px solid var(--cc-cookie-table-border);
    }
  
    #s-bl td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 35%;
    }
  
    #s-bl td:before {
      position: absolute;
      left: 1rem;
      padding-right: 0.625em;
      white-space: nowrap;
      content: attr(data-column);
      color: var(--cc-text);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    #cm .c-bn,
    .cc_div .c-bn {
      width: 100%;
      margin-right: 0;
    }
  
    #s-cnt #s-rall-bn {
      margin-left: 0;
    }
  
    .cc_div #c-bns {
      flex-direction: column;
    }
  
    #c-bns button + button,
    #s-cnt button + button {
      margin-top: 0.625em;
      margin-left: 0;
      float: unset;
    }
  
    #cm.cloud,
    #cm.box {
      left: 1em;
      right: 1em;
      width: auto;
    }
  
    #cm.cloud.right,
    #cm.cloud.left {
      margin: 0;
    }
  
    .cc_div .cloud #c-bns,
    .cc_div .cloud #c-inr,
    .cc_div .cloud #c-inr-i {
      display: block;
      width: auto;
      min-width: unset;
      h2{
        font-size: 18px;
        font-weight: bold;
        position: relative;
        color: var(--cc-pagePrimary-textColor);
        width: 100%;
      }
    }
  
    .cc_div .cloud #c-txt {
      font-size: 0.9em;
      color: var(--cc-pagePrimary-textColor)
    }
  
    .cc_div .cloud #c-bns {
      margin-top: 1.625em;
    }
  }
  