.pageSpinner {
  padding: 1.5em;
  width: 1em;
  margin: 10px auto;
  border: 2px solid transparent;
  border-top: 3px solid currentColor;
  border-radius: 100%;
  animation: spin 1s infinite linear;
  position: absolute;
  top: 40%;
  left: 50%;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
