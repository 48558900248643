.ios-share-container {
  display: none;
  text-align: center;
  line-height: 1.5;
  justify-content: center;
  justify-self: center;
  span {
    color: black;
  }
  @media (max-width: 1366px) {
    display: block;
    width: 95vw;
    max-width: 480px;
    position: fixed;
    bottom: 20px;
    z-index: 50;
    background-color: darken($color: #f4f4f4, $amount: 0);
    font-family: sans-serif;
    border: 2px solid white;
    padding: 1em;
    border-radius: 8px;
    img {
      max-width: 20px;
    }
    cursor: pointer;
  }
  @media (max-width: 767px) and (orientation: portrait) {
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -15px;
      border-width: 15px;
      border-style: solid;
      border-color: whitesmoke transparent transparent transparent;
    }
  }
}
.ios-share {
  width: 100%;
  display: flex;
  justify-content: center;
}
