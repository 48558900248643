.error-screen {
  height: 100%;
  text-align: center;
  padding: 30px 0;
}

.error-screen .logo {
  width: 250px;
  margin-bottom: 20px;
}

.error-screen .fade__container {
  position: relative;
}

.error-screen .fade__content {
  animation: fadeAndDip 0.4s ease-out forwards;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
}

.error-screen #code {
  font-size: 9em;
  height: 130px;
  margin: 20px auto;
  font-weight: 900;
}

.error-screen #code .fade__content {
  text-shadow: 1px 1px 1px #00000069;
}

.error-screen #message {
  font-size: 1.2em;
  height: 50px;
}

.error-screen #message .fade__content {
  animation-delay: 0.3s;
  top: 0;
}

.error-screen .redirect {
  margin: 10px auto;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  width: 150px;
  opacity: 0.9;
  transition: font-weight 0.1s linear;
}

.error-screen .redirect:hover {
  opacity: 1;
  cursor: pointer;
  font-weight: bold;
  transition: font-weight 0.1s linear;
}

.error-screen .redirect .icon-container {
  position: relative;
  overflow: hidden;
  width: 20%;
  height: 24px;
}

.error-screen .redirect i {
  position: absolute;
  left: 100%;
  right: 0;
  transition: right 0.1s linear;
  margin: 0 auto;
  animation: slideIn 0.1s ease-out 1.2s forwards;
}

.error-screen .redirect:hover .icon-container i {
  right: 30%;
  transition: right 0.1s linear;
}

.error-screen .fade__content.redirect__text {
  animation-delay: 0.6s;
}

.error-screen .viewlift {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  padding: 5px;
}

.error-screen .viewlift p {
  font-size: 20px;
  margin-right: 5px;
}

.error-screen .viewlift img {
  height: 25px;
}

@keyframes fadeAndDip {
  from {
    bottom: 20px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from { left: 100%; }
  to { left: 0; }
}

@keyframes pokeLeft {
  from { right: 0; }
  to { right: 20%; }
}