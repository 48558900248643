.popup-view.hide {
    display: none;
}

.popup-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.95);
    color: #fff;
    overflow-y: auto;
    transform: translate3d(0px, 0px, 0px);
}

.type-notice02{
    background: rgba(0, 0, 0, 0.856);
}

.popup-view .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.popup-view .centered02 {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    width: 100%;
    max-width: 800px;
}

.popup-view .error-msg {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.popup-view .centered.opaque {
    background: #333;
}

.popup-view .centered.padded {
    padding: 20px;
    }
.back02-title {
    display: flex;
    align-items: center;
}
.alignRight {
    width: max-content !important;
    right: 20px;
}

@media (max-width: 700px) {
    .popup-view .centered.padded {
        padding-top: 35px;
        background: #333;
        border-radius: 4px;
    }
}

.popup-view .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 4em;
    cursor: pointer;
    z-index: 20000;
    transform: scale(1);
    transition: opacity 0.1s, transform 0.1s;
    opacity: 0.9;
}
.popup-view .close i{
    display: block;
}

.popup-view .close:hover {
    opacity: 0.9;
    transform: scale(1.2);
    transition: opacity 0.1s, transform 0.1s;
}

.popup-view .close02{
    position: absolute;
    top: 20px;
    right: 5px;
    font-size: 50px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    transform: scale(1);
    transition: opacity 0.1s, transform 0.1s;
    opacity: 0.9;
}
.type-search .close02{
    position: absolute;
    top: 20px;
    right: 5px;
    font-size: 50px;
    width: 100px;
    height: 50px;
    cursor: pointer;
    transform: scale(1);
    transition: opacity 0.1s, transform 0.1s;
    opacity: 0.9;
}

.popup-title {
    font-size: 20px;
    margin-left: 50px;
}
.fixed-width {
    max-width: 1040px;
}
.back-close {
    margin: 6% auto;
    /* padding: 10px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}
.m-imp {
    margin: 0 !important;
}
.popup-view .right02 {
    position: absolute;
    right: 20px;
}
.popup-view .back02 {
    position: absolute;
    margin-bottom: 10px;
    width: 35px;
    height: 35px;
    transform: rotate(180deg);
}

@media (min-width: 768px) {
.popup-view .entitlement-overlay-wrapper{
    position: static;
    margin-top: 0px;
}
}

@media (max-width: 700px) {
    .popup-view .close {
        right: 20px;
        top: 0px;
    }
    .back02-title {
        display: none;
    }

    .popup-view .close02{
        background: rgba(0, 0, 0, 0.5);
        font-size: 30px;
        width: 30px;
        height: 30px;
        top: 5px;
        right: 5px;
    }
    .m-imp {
        margin-top: 10px !important;
    }
    .back-close{
        margin-top: 20px;
    }
}

@media(min-width: 600px) {
    .popup-view .centered {
        margin-top: 25px;
    }
    /* .back02-title {
        display: flex;
    } */
}

@media(min-width: 1025px) {
    /* .popup-view .centered {
        margin-top: 0px;
    } */
    .popup-view .auth-view.centered {
        margin-top: -67px;
    }
}

.popup-view .button {
    /* padding: 10px 10px; */
    min-width: 130px;
    text-transform: uppercase;
    font-size: 0.8em;
    display: inline-block;
    outline: none;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0);
    outline: none;
    color: inherit;
}

.popup-view .button.left-margin {
    margin-left: 20px;
}

/* .popup-view .button.fat {
    padding: 10px 20px;
} */

.popup-view .button.disabled {
    pointer-events: none;
    opacity: .8;
}

.popup-view button:disabled {
    pointer-events: none;
    opacity: .8;
}

.popup-view .button.outlined {
    border: 1px solid #666;
}

.popup-view .button.inactive {
    border: 1px solid #666;
    background: #222;
}

.popup-view .button.processing {
    opacity: 0.7;
    pointer-events: none;
}

.popup-view .mobile-logo-image {
    margin: 15px auto 10px auto;
    max-width: 150px;
    display: none;
}

@media (max-width: 700px) {
    .popup-view .centered {
        height: 100%;
        overflow-y: auto;
    }
    .popup-view .mobile-logo-image {
        display: block;
    }
}

@media (min-width:700px) and (max-width:800px){
    .popup-view .centered {
        top:60%;
        overflow-y: auto;
    }
}

.popup-view .centered p {
    margin-bottom: 3px;
}

.popup-view input[type=text],
.popup-view input[type=email],
.popup-view input[type=password] {
    width: 100%;
    padding: 7px;
    margin: 10px 0;
}

.popup-view .error-box {
    margin: 5px;
    font-size: .8em;
    color: red;
}

.popup-view .error {
    color: red;
    font-size: 0.9em;
}