@import url('//code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import "./node_modules/@silvermine/videojs-chromecast/src/scss/videojs-chromecast";
@import "./node_modules/@silvermine/videojs-airplay/src/scss/videojs-airplay";
@font-face {
    font-family: 'Guess Sans';
    src: url("../fonts/GuessSans.otf");
    font-display: swap;
};
@font-face {
    font-family: 'Din Pro';
    src: url("../fonts/DINPro.otf");
    font-display: swap;
};
@font-face {
    font-family: 'Proxima Nova';
    src: url("../fonts/Proxima_Nova.otf");
    font-display: swap;
};
@font-face {
    font-family: 'Din Pro light';
    src: url("../fonts/DINPro-Light.otf");
};
@font-face {
    font-family: 'Din Pro medium';
    src: url("../fonts/DINPro-Medium.otf");
}
@font-face {
    font-family: 'Sequel Sans';
    src: url("../fonts/Sequel-Sans-Display-Medium.ttf");
    font-display: swap;
}
html,
body {
    font-family: 'Open Sans';
}

a,
a:hover {
    text-decoration: none;
}

a,
button,
iframe,
div {
    &:focus {
        box-shadow: none;
        outline: none;
    }
    &:hover,
    &:active {
        box-shadow: none;
    }
}

// /* Gives arrow on the top right to a div */

// .arrow_box {
//     position: relative;
//     background: #eee;
//     border: 1px solid #bbb;
// }

// .arrow_box:after,
// .arrow_box:before {
//     bottom: 100%;
//     left: 90%;
//     border: solid transparent;
//     content: " ";
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
// }

// .arrow_box:after {
//     border-color: rgba(238, 238, 238, 0);
//     border-bottom-color: #eee;
//     border-width: 10px;
//     margin-left: -10px;
// }

// .arrow_box:before {
//     border-color: rgba(187, 187, 187, 0);
//     border-bottom-color: #bbb;
//     border-width: 11px;
//     margin-left: -11px;
// }

a.cta {
    display: inline-block;
    text-decoration: none;
}

a.cta.button {
    padding: 10px;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
}

a.cta:hover {
    text-decoration: none;
}

.clearfix {
    clear: both;
}

.facebook-color-background {
    background: #3b5998;
}

.google-color-background {
    background: #d34836;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clearfix {
    clear: both;
}
