@keyframes mymoveforappstorepopup {
  from {
      margin-top: -100px
  }
  to {
      margin-top: 0;
  }
}

.appstore-suggestion {
  z-index: 1;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, .0785);
  animation: mymoveforappstorepopup .75s linear;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #222;
  padding: 20px;
  color: #fff;
  font-weight: 600;
  grid-column: 1 / -1;

  .desc{
      display: flex;
      align-items: center;
      span{
          display: inline-block;
          margin:0 10px 0 0;
      }
  }
  @media(max-width: 400px) {
      .desc{
          font-size: 0.8em;
      }
  }

  .close-button {
      &:focus {
          outline: 1px dotted;
      }
      svg {
          cursor: pointer;
          width: 1.25em;
          height: 1.25em;
          fill: #fff;
          margin-left: 5px;
      }
  }
} 