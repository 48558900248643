.modules.skin-ad {
    max-width: 1000px;
    margin: auto;
}

.modules.skin-ad .module{
    z-index: 0;
}

#viewlift-site-skin {
    display: none;
}

@media(min-width: 1025px) {
    #viewlift-site-skin {
        position: fixed;
        display: block;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 0;
        background-attachment: fixed;
        background-position: top center;
        background-repeat: no-repeat;
    }
    .site-skin-click-area {
        display: block;
        position: fixed;
        top: 0;
        height: 100vh;
        width: calc((100vw - 1000px) / 2);
    }
    .site-skin-click-area.site-skin-click-area-left {
        left: 0;
    }
    .site-skin-click-area.site-skin-click-area-right {
        right: 0;
    }
}