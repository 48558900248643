.skip-nav {
  position: fixed;
  opacity: 0;
  top: -40px;
  left: 10px;
  z-index: 999;
  border-radius: 5px;
  padding: 10px;
  background: #3c763d;
  color: white;
  text-decoration: underline;
  transition: top 0.3s, opacity 0.3s;

  &:focus {
    top: 10px;
    opacity: 1;
    transition: top 0.3s, opacity 0.3s;
  }
}