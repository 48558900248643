.carousel .slick-slide {
  width: 100%;
}

.carousel .slick-track {
  display: flex;
  margin-left: auto;
}

.carousel-item .badge-image {
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.blurry-back{
  filter: blur(4.6px);
  -webkit-filter: blur(4.6px);
}
ul.slick-custom-dots { 
  position: absolute;
  background-color: transparent;
  bottom: 1%;
  right: 5%;
  display: flex  !important;
  height: 50px;
  flex-direction: row;
  list-style: none;
  cursor: pointer;
  justify-content: flex-end;
}
.slick-custom-dots li{
 height: 13px;
 width: 14px;
 margin: 1%; 
}
.slick-custom-dots li div{
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.6;
  overflow: hidden;
}
.slick-custom-dots .slick-active {
  width: 49px;
}

.slick-custom-dots .slick-active div{
  border-radius: 40px;
  width: 100%;
  opacity: 1;
}


.carousel-item .custom-foot{
  
  z-index: 6;
  overflow-wrap: break-word;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 40%;
}

.carousel-item .custom-foot .custom-info{
  margin-bottom:44px;
  display:flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-evenly;
}

.carousel-item .custom-info .title{
  font-size: 60px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #eeeeee;
  line-height: 80px;
}
.preview-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
}

.custom-info .more{
  font-size:16px;
  text-transform: capitalize;
  font-weight: normal;
  padding-top: 10px;
}

.custom-foot .watch-type{
  display: flex;
  font-size: 30px;
  align-items: center;
}

.custom-foot .cta{
 width: 256px;
 height: 72px;
 display: flex;
 font-size: 100%;
 align-items: center;
 justify-content: center;
 margin: 2px 10px;
}

.carousel-item .custom-play{
  width:72px;
  height: 72px;
  font-size: 1.875em;
  border-radius: 50%;
  background-color: var(--site-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-cta{
  background: transparent !important;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 100%;
  font-weight: bold;
  border-radius: 40px;
  padding: 0.5rem 0.8rem;
  border: 2px solid;
}
.custom-cta:hover{
color: #eeeeee;
}

.rounded-button{
  border-radius: 40px !important;
  border: none !important;
  font-weight: bold;
}
.carousel-item .modify-content{
  display: flex;
  padding: 0px 120px 0px 120px;
  height: 615px;
  width: 100%;
  justify-content: space-between;
}
.PIP-mode{
  height: 75%;
  width: 50%;
  align-self: center;
  justify-self: flex-end;
  z-index: 1;
}

.share{
  padding: 0px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:20px;
  opacity: 0.9;
  border-radius: 20px;
  z-index: 999;
  background-color:transparent;
  box-sizing: border-box;
  i{
    font-size: 24px;
  }
  .logo-x-icon{
    vertical-align: baseline;
  }
}
.parental-guidance-container{
  font-size: 12px;
  border-radius: 28px;
  border: white 1px solid;
  padding:1px 5px;
  margin-left: 2px;
  min-width: 57px;
}
.customMessage{

  font-size: 25px;
  letter-spacing: 0.1px;
  color:#eeeeee;
  opacity: 0.7;
  font-weight: bold;

}


.carousel-height{
  height:615px;
}

.custom-foot .icons{
  margin-top:46px;
  margin-left: 30px;
  display: flex;
  z-index:20;
  .icon{
    margin-right: 40px;
  }
  .share-container{
    display: flex;
    justify-content: space-between;
  }
  
}
@media(min-width: 1025px) {
  .carousel .slick-slide.slick-active.slick-center {
      opacity: 1;
  }
  .carousel .slick-slide.slick-active.slick-center .background-black,
  .carousel .slick-slide.slick-active .background-black {
      background: transparent;
  }
  .carousel {
    background: #000;
  }
  .carousel .foot{
    display: none;
  }
  .carousel32 .foot {
    display: block;
  }
  .carousel .slick-slide.slick-active.slick-center .foot{
    display: block;
  }
  .background-black {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 0.7;
      background: #000;
  }
  
}

.carousel-item .duration {
  background: #4a4a4a;
  font-size: 0.625rem;
  border-radius: 4px;
  padding: 5px;
  font-weight: bold;
  margin-left: 20px;
  .item:not(:first-child):before {
    content: " - ";
  }
}

.carousel-item .stop-watch {
  background: #4a4a4a;
  margin-right: 20px;
  border-radius: 4px;
}


// .server-side-rendering .carousel .slick-slide:not(:nth-child(2)) {
//   display: none;
// }

.carousel-item {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  display: block;
}

.carousel-item .image img {
  object-fit: cover;
  width: 100%;
}

.carousel .slick-arrow {
  display: none;
}

.instructor {
  font-size: 1.25em;
}

.carousel-item .event-time {
  font-size: 1.25em;
  padding: 5px;
  z-index: 2;
  margin-left: 5%;
  border: 1px solid #fff;
  border-radius: 4px;
  position: absolute;
  top: 0px;
  display: none;
}

.carousel-item .schedule-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  .day-time {
    margin-bottom: 10px;
    font-size: 1em;
    font-weight: 600;
  }
  .desc {
    font-size: 1.1em;
    display: none;
  }
}

.concept-carousel {
  .schedule-carousel-container {
    .carousel {
      padding-bottom: 20px;
      .slick-dots {
        bottom: -14px;
        li {
          width: initial;
          height: initial;
          button {
            width: 20px;
            height: 2px;
            opacity: 0.2;
            border-radius: 2px;
            background-color: white;
            padding: 0;
            margin: 0 3px;
            &:before {
              display: none;
            }
          }
          &.slick-active button {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (min-width: 600px) {
  a.carousel-item .foot .info {
    font-size: 1.2em;
    text-align: initial;
    display: block;
  }
  a.carousel-item .foot .cta {
    display: inline-block;
  }
  a.carousel-item .play-icon {
    display: none;
  }
  a.carousel-item .foot .info .title {
    font-size: 1.6em;
  }
}

.carousel32 {
  .carousel {
    .carousel-item {
      padding-bottom: 28.55%;
    }
  }
}
@media (max-width: 767px) {
  a.carousel-item .play-icon {
    font-size: 3em;
  }
  .carousel32 {
    .carousel {
      .use-sixteen {
        padding-bottom: 50%;
      }
    }
  }
  .custom-foot .icons{
    margin-left:10px;
    margin-top:16px;
    .icon{
      margin-right: 25px;
    }
  }
 
  ul.slick-custom-dots{
    bottom:14.6px;
    right:0%;
    height:20px;
    margin: auto;
  }
  .slick-custom-dots li {
    height:8px;
    width: 7px;
    margin:0px;
    margin-right:1px;
  }
  .slick-custom-dots li div{
    width: 100%;
    height: 100%;
  }
  .slick-custom-dots .slick-active {
    width: 25px;
    height:8px;
  }

  .slick-custom-dots .slick-active div{
    border-radius: 20px;
    width: 100%;
    opacity: 1;
  }
  .customMessage{
    font-size: 16px;
  }

}

@media screen and (min-width: 1080px) {
  // .carousel-item {
  //   padding-bottom: 40%;
  // }
  .carousel-item.schedule-carousel {
    padding-bottom: 28.125%;
  }
  .carousel-item .schedule-info .desc {
    display: block;
    width: 30%;
  }
  .carousel-item .event-time {
    font-size: 1.75em;
  }
}

.carousel-item .image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-item .foot {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 30px 10px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

@media (min-width: 1080px) {
  .carousel-item .foot {
    padding: 40px 50px;
  }
  .carousel .slick-arrow {
    z-index: 1;
    bottom: 12px;
    top: initial;
    border-width: 0 0 2px 2px;
    border-style: solid;
    width: 15px;
    height: 15px;
    display: block;
  }
  .carousel .slick-arrow:before {
    content: none;
  }
  .carousel .slick-arrow.slick-prev {
    transform: rotate(45deg);
  }
  .carousel .slick-arrow.slick-next {
    transform: rotate(-135deg);
  }
  .carousel-item.schedule-carousel {
    .schedule-info {
      bottom: 20px;
    }
    .day-time {
      display: none;
    }
    .badge-desktop-image.badge-image,
    .image .desktop-image {
      display: block !important;
    }
    .badge-mobile-image.badge-image,
    .image .mobile-image {
      display: none !important;
    }
  }
  .carousel-item .event-time {
    top: 10px;
    display: block;
  }
}

.carousel-item .foot .cta {
  padding: 10px 0;
  font-size: 0.9em;
  width: 160px;
  text-align: center;
  margin-right: 10px;
  text-transform: uppercase;
  display: none;
}

.carousel-item .play-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-item .play-icon .ion-ios-play {
  opacity: 0.7;
}

.schedule-carousel .badge-desktop-image.badge-image,
.schedule-carousel .image .desktop-image {
  display: none;
}

/* Todo: Until Anthony fixes it */

.carousel-item .foot .cta.secondary,
.carousel-item .foot .cta.secondary:hover {
  background: rgba(0, 0, 0, 0);
}

.carousel-item .foot .info {
  padding-bottom: 10px;
  vertical-align: middle;
  font-size: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.carousel-item .foot .info .title {
  font-size: 1.2em;
  font-weight: bolder;
  margin-right: 10px;
  color: #fff;
  cursor: default;
}

.carousel-item .foot .info .more {
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.8em;
  display: inline-block;
  cursor: default;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding-top: 4px;
}

.carousel .slick-dots li button:before {
  color: #fff;
  font-size: 15px;
}

.carousel .slick-dots li {
  margin: 0 2px;
}

.carousel .slick-dots {
  bottom: 10px;
}


.carousel-gradient{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  opacity: 0.8;
}


.schedule-carousel-container {
  .slick-slide {
    padding: 0 5px;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transition: transform 0.5s ease-in;
  }
  .schedule {
    margin: 5px;
    display: flex;
    .live-games {
      background: red;
      // width: 200px;
      height: 25px;
      display: flex;
      font-size: 0.85em;
      padding: 5px 10px;
      line-height: 1.15;
      font-weight: bold;
      text-transform: uppercase;
      flex-direction: column;
      justify-content: center;
      clip-path: polygon(0 0, 92% 0%, 100% 50%, 92% 100%, 0% 100%);
    }
    .schedules-container {
      display: flex;
      overflow-x: scroll;
      .schedules {
        display: flex;
        .day-time {
          color: #b3b3b3;
          font-size: 0.688em;
          cursor: pointer;
          width: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          line-height: 1.3;
          text-transform: uppercase;
          &.current {
            color: red;
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .schedule-carousel-container {
    .carousel {
      padding: 0.6875rem 0.7rem;
    }
    .schedule {
      margin: 10px 20px;
      .live-games {
        font-size: 0.688em;
      }
    }
    .slick-track {
      margin-left: 5px;
    }
  }
}

.schedules-container::-webkit-scrollbar {
  display: none;
}


.schedules-container {
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
}
@media (min-width:769px) and (max-width:1600px){

  .carousel-item .custom-info .title{
    font-size: 50px;
    z-index: 20;
    line-height: 66px;
  }
  .custom-foot .watch-type{
    font-size: 18px;
  }
  .custom-foot .cta{
    width: 170px;
    height: 50px;
   }
   .carousel-item .custom-play{
    width:50px;
    height: 50px;
  }

}
@media (max-width: 768px) {
  .schedule-carousel-container {
    .schedule {
      .live-games {
        height: 35px;
      }
    }
  }
  .carousel-item .modify-content{
    position: absolute;
    padding: 50px 20px 20px 20px;
    height: 70vh;
    width: 100%;
    bottom: 20px;
  }
  .carousel-item .custom-foot{
    align-self: flex-end;
    overflow-wrap: break-word;
    display: block;
    width: 80%;
  }
  .carousel-item .custom-info .title{
    font-size: 50px;
    line-height: 66px;
  }
  .custom-foot .watch-type{
    font-size: 16px;
  }
  .custom-foot .cta{
    width: 170px;
    height: 50px;
   }
   .carousel-item .custom-play{
    width:50px;
    height: 50px;
    font-size: 1.25em;
  }
  .pipEnabled{
    display: none !important;
  }
  .PIP-mode{
    display: none;
  }
  .blurry-back{
    filter: blur(0px);
    -webkit-filter: blur(0px); 
  }
}
@media (min-width:200px) and (max-width:425px){
  .carousel-gradient{
    opacity: 0.8;
  }

  .carousel-item .modify-content{
    position: absolute;
    
    padding: 30px 10px 0px 0px;
    height: 70vh;
    width: 100%;
    bottom: 20px;
  }
  .carousel-height{
    height:500px;
  }
  .carousel-item .custom-foot{
    align-self: flex-end;
    overflow-wrap: break-word;
    display: block;
    max-width: 80%;
  }
  .carousel-item .custom-foot .custom-info{
    margin-bottom: 15px;
  }
  .carousel-item .custom-foot{
    padding-left:2px;
    max-width: 100%;
  }
  .carousel-item .custom-info .title{
    font-size: 25px;
    line-height: 41px;
  }
  .custom-foot .watch-type{
    font-size: 16px;
  }
  .custom-foot .cta{
    width: 130px;
    height: 33px;
    border-radius: 40px;
   }
   .custom-foot .custom-cta{
    width: 130px;
    height: 33px;
   }
   .carousel-item .custom-play{
    width:33px;
    height: 33px;
    font-size: 1.2em;
  }
  .share-container{
    width: 30vw;
  }
  ul.slick-custom-dots{
    right:5%;
  }
}
.bold-custom-arrow{
  width: 25px !important;
  height: 25px !important;
  border-width: 0 0 5px 5px !important;
}

.watchTrailerButton{
  border-radius: 40px !important;
  font-weight: bold;
  border: 1px solid #ffffff !important;
  background: transparent !important;

}