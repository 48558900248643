.deviceError {
    width: 100%;
    height: 30px;
    text-align: center;
    padding: 7px;
}
.modules {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
    min-height: 100vh;
    opacity: 1;
}
.VideoJS {
    position: relative;
}
.modules .module {
    grid-column: 1 / -1;
    max-width: 100%;
    background: inherit;
    opacity: 1;
    margin-bottom: 5px;
}

.video-tray {
    margin: 0 20px;
}

.loading-centered{
    position: absolute;
    top: 50%;
    left: 50%;
}
.ssrmodule, .BlockTitle-ssr, .slick-slide-ssr{
    overflow: hidden;
    position: relative;
}
.BlockTitle-ssr {
    margin: 20px 35px 5px;
}

.loading-bg {
    background: linear-gradient( to right, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0.10) 80%, rgba(255, 255, 255, 0.0) 100% );
}
.hide-skeleton {
    display: none;
}

  @media(max-width: 480px) {
    .style7 {
        margin: 0 10px 5px !important;
    }
  }
@media(min-width: 768px) {
    .module.left {
        grid-column: span 2;
        margin-left: 10px;
    }
    .module.right {
        grid-column: span 1;
        margin-right: 10px;
    }
    .module.full {
        min-height: 450px;
    }
    .module.half {
        min-height: 225px;
    }
    .module.fullCarousel {
        min-height: 220px; 
    }
}

@media(max-width: 1024px) {
    .deviceError {
        top: 55px;
    }
}
@media(max-width: 660px) {
    .deviceError {
        height: 40px;
    }
}

@media(min-width: 1200px) {
    .module.left {
        margin-left: calc((100vw - 1200px)/2);
    }
    .module.right {
        margin-right: calc((100vw - 1200px)/2);
    }
    .module.full {
        min-height: 480px;
    }
    .module.half {
        min-height: 370px;
    }
    .module.fullCarousel {
        min-height: 300px; 
    }
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .modules {
        display: flex;
        flex-direction: column;
    }
    .module.left {
        width: 70%;
        margin: 0 auto;
    }
    .module.right {
        width: 50%;
        margin: 0 auto;
    }
    .grid-view {
        display: flex;
        flex-wrap: wrap;
    }
    .grid-view .event-grid-item {
        margin: 10px;
        width: 31%;
    }
    .calendar-days,
    .mobile .calendar-days,
    .calendar-week-days {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 10px;
    }
}

/* Hide Google recaptcha badge */
.grecaptcha-badge { 
    visibility: hidden;
}
