.share .share-toggle__icon {
    display: flex;
    flex-direction: column;
    text-align: -webkit-right;
    padding: 0 4px;
}

.share .share-toggle__icon .small-rect {
    margin-bottom: 2px;
    width: 3px;
    height: 3px;
    background: currentColor;
}

.share-toggle__options {
    display: none;
}

.share-toggle--open .share-toggle__options {
    display: flex;
    padding: 10px;
    width: 200px;
    flex-direction: column;
    position: absolute;
    border: 1px solid #fff;
    z-index: 99;
    color: #fff;
    background: #000;
}

.share-toggle .share-option {
    margin-bottom: 5px;
    cursor: pointer;
    white-space: nowrap;
}
.share-toggle__options .twitter-share .logo-x-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.share-toggle .share-option .share-text {
    font-size: 0.8em;
    text-transform: capitalize;
}

.share-toggle--open .share-toggle__options .icon {
    color: #000;
    text-align: center;
    display: inline-block;
    font-weight: bolder;
    padding: 3px;
    width: 22px;
    border-radius: 11px;
    margin-right: 5px;
    background: rgba(255, 255, 255, .7);
    font-size: 1em;
}

.share-toggle--open .share-toggle__options:after {
    content: '';
    left: calc(100% - 10px);
    position: absolute;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    bottom: 40%;
    width: 20px;
    height: 20px;
    background: #000;
    transform: rotate(45deg);
}

.share-toggle__options svg {
    width: 1.25em;
}

.share-toggle--open .share-toggle__options.overflow:after {
    top: 90%;
    left: 50%;
    transform: rotate(135deg);
}
