:root {
    --skeletonBackgroundColor : #3a3939
}

* {
margin: 0;
box-sizing: border-box;
}
.style2 {
    background-color: var(--skeletonBackgroundColor);
    height: 28vw;
    margin-bottom: 5px;
}

.items {
    margin: 0;
}

.style3 {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    width : 8610px;
    height: 288px;
    display: flex !important;
}
.style4 {
    /* padding: 20px; */
    overflow: hidden;
}
.style5 {
    width : 180px;
    background-color: var(--skeletonBackgroundColor);
    margin: 0 3px;
    height: 210px;
}
.style7 {
    background-color: var(--skeletonBackgroundColor);
    height: 35px;
    width: 300px;
    margin-bottom: 5px;
}
.style8 {
    margin-bottom: 5px;
    height: 70px;
    background-color: var(--skeletonBackgroundColor);
}

.style9 {
    display: none;
}
@media(max-width : 1920px) {
   .style3 {
    width: 100%;
   }
}
@media(max-width : 768px) {
    .style2 {
        height: 49vw;
    }
    .style3 {
        width: 1500px;
    }
    .style5 {
        width: 135px;
        height: 190px;
    }
    .style7 {
        width: 165px;
    }
    .style8 {
        height: 50px;
    }
    .style9 {
        display: flex;
        height: 80px;
    }
}
@media(max-width : 600px) {
   .style5 {
    width: 160px;
   }
}
@media(max-width : 480px) {
    .style3 {
        width: 1200px;
    }
    .style5 {
        width: 130px;
    }
    .BlockTitle-ssr {
     margin: 0 0 5px 5px;
    }
    .style2 {
        height: 210px;
    }
}
@media(max-width : 400px) {
    .style2 {
        height: 160px;
    }
    .style5 {
        height: 160px;
        width: 100px;
        margin: 0 6px;
    }
}
